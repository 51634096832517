<template>
    <div>
        <b-modal :no-close-on-backdrop="true" id="assignment-confirmation-modal" hide-footer ref="ref-assignment-confirmation-modal" centered
            title="Confirm Coupon Assignment" @hidden="closeAssignmentConfirmationModal">
            <div class="p-1">
                <p>
                    Are you sure you want to assign this coupon ({{ currentCouponCode }}) to {{ currentStudentName }}?
                </p>

                <div class="d-flex justify-content-end mt-3">
                    <b-button variant="primary" @click="onStudentSelect">Confirm</b-button>
                </div>
            </div>
        </b-modal>

        <b-card>
            <b-col class="m-2 w-100">
                <!-- Table Filters -->
                <b-row class="mr-3 mb-2 align-items-end">
                    <b-col class="col-md-12">
                        <b-form-input v-model="filters.search" class="d-inline-block mr-1" placeholder="Search..."
                            @input="onSearch" />
                    </b-col>
                </b-row>
            </b-col>
        </b-card>
        <b-card>
            <b-table id="couponsTable" ref="refCouponListTable" class="position-relative" :items="coupons" responsive
                :fields="tableColumns" primary-key="cc_id" show-empty empty-text="No matching records found" selectable>
                <template #cell(student_user_id)="data">
                    <div>
                        <v-select :reduce="(student) => student.student_user_id" label="unique_name" class="w-100"
                            :value="data.item.student_id" :options="assigned_students" :closeOnSelect="true"
                            :disabled="data.item.student_id ? true : false" :clearable="false"
                            @input="showAssignmentConfiramtionModal(data.item.cc_id, $event)">
                            <template #selected-option="selectedData">
                                <b-avatar :src="FILESURL + selectedData.user_profile_image" size="16"
                                    class="align-middle mr-50" />
                                <span> {{ selectedData.unique_name }}</span>
                            </template>
                        </v-select>
                    </div>
                </template>
            </b-table>

            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted"> {{ totalCoupons }} entries </span>
                    </b-col>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination aria-controls="couponsTable" v-model="pagination.currentPage"
                            :total-rows="totalCoupons" :per-page="pagination.perPage" first-number last-number
                            class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="onChange">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import CommonServices from "@/apiServices/CommonServices";
import store from "@/store";
import {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BAvatar,
    BFormInput,
    BModal,
    BButton
} from "bootstrap-vue";
import vSelect from "vue-select";
import { FILESURL } from "@/config";

export default {
    components: {
        BCard,
        BTable,
        BPagination,
        BRow,
        BCol,
        BAvatar,
        BFormInput,
        vSelect,
        BModal,
        BButton,
    },
    data() {
        return {
            FILESURL,
            pagination: {
                currentPage: 1,
                perPage: 10,
                perPageOptions: [5, 10, 20, 30],
            },
            debounce: null,
            coupons: [],
            totalCoupons: null,
            users: [],
            tableColumns: [
                { key: 'cc_id', label: 'Coupon ID', sortable: false },
                { key: 'cc_name', label: 'Coupon Name', sortable: false },
                { key: 'cc_code', label: 'Coupon Code', sortable: false },
                { key: 'cc_flat_amount', label: 'Flat Amount', sortable: false },
                { key: 'cc_upto_amount', label: 'Upto Amount', sortable: false },
                { key: 'student_user_id', label: "Student", sortable: false }
            ],
            filters: {
                search: null
            },
            assignObj: {
                cc_id: null,
                ca_id: null,
                student_id: null
            },
            currentStudentName: "",
            currentCouponCode: "",
            selectedStudent: null
        };
    },
    computed: {
        assigned_students() {
            return store.getters["user/getAssignedStudents"].data;
        },
    },
    methods: {
        async getAllCoupons() {
            const res = await CommonServices.getAssignedCoupons({ ...this.filters, currentPage: this.pagination.currentPage, perPage: this.pagination.perPage });
            if (res.data.status) {
                this.coupons = res.data.data.data;
                this.totalCoupons = res.data.data.total;
            }
        },
        async assignCoupon() {
            const res = await CommonServices.assignCouponToStudent(this.assignObj);
            if (res.data.status) {
                this.getAllCoupons();
            }
            console.log(this.assignObj)
        },
        onChange() {
            this.$nextTick(() => {
                this.getAllCoupons();
            });
        },
        onSearch() {
            this.pagination.currentPage = 1;
            if (this.debounce) {
                clearTimeout(this.debounce);
                this.debounce = null;
            }
            this.debounce = setTimeout(() => {
                this.onChange();
            }, 1000);
        },

        closeAssignmentConfirmationModal() {
            this.assignObj.cc_id = null;
            this.assignObj.student_id = null;
            this.currentCouponCode = ""
            this.currentStudentName = ""
        },

        showAssignmentConfiramtionModal(cc_id, student_id) {
            this.assignObj.cc_id = cc_id;
            this.assignObj.student_id = student_id;

            const coupon = this.coupons.find(coupon => coupon.cc_id === this.assignObj.cc_id);
            this.currentCouponCode = coupon.cc_code
            const student = this.assigned_students.find(s => s.student_user_id === this.assignObj.student_id)
            this.currentStudentName = student.user_firstname + " " + student.user_lastname

            this.$refs['ref-assignment-confirmation-modal'].show()
        },

        onStudentSelect() {

            // Find the coupon with the given cc_id in the coupons array
            const coupon = this.coupons.find(coupon => coupon.cc_id === this.assignObj.cc_id);

            // Include ca_id if the coupon is found and has a ca_id
            if (coupon && coupon.ca_id) {
                this.assignObj.ca_id = coupon.ca_id;
            } else {
                this.assignObj.ca_id = null; // Clear ca_id if not found
            }

            this.assignCoupon();
            this.$refs['ref-assignment-confirmation-modal'].hide()
        },
    },
    beforeMount() {
        this.getAllCoupons();
    }
}
</script>
